import { render, staticRenderFns } from "./BottomTabBar.vue?vue&type=template&id=64813cb6&scoped=true&"
import script from "./BottomTabBar.vue?vue&type=script&lang=js&"
export * from "./BottomTabBar.vue?vue&type=script&lang=js&"
import style0 from "./BottomTabBar.vue?vue&type=style&index=0&id=64813cb6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64813cb6",
  null
  
)

export default component.exports