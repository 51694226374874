<template>
  <div class="tab-bar">
    <div class="tab-group">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="tab-bar-item"
        @click="onChange(item, index)"
      >
        <div
          class="iconfont"
          :class="path === item.url ? `${item.icon} active ` : `${item.icon} `"
        ></div>
        <div class="tab_text" :class="{ active: path === item.url }">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    selected: {
      default () {
        return 0
      }
    },
    path: {
      default () {
        return 0
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    list () {
      return this.$t('tabBar.daoList')
    }
  },
  methods: {
    onChange (item, index) {
      if (item.url === 'comingSoon') {
        return this.$toast(this.$t('common.ComingSoon'))
      }
      this.$gbUtils.handleToPage(item.url)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #fff;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 999;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
}
.iconfont {
  font-size: 20px;
  color: #a2a2a2;
}
.tab-group {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
.tab-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.tab-bar-item {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.tab_text {
  font-size: 10px;
  color: #a2a2a2;
  margin-top: 5px;
}
.active {
  color: #000000;
}
</style>
