<template>
    <div class="dap-wrapper">
      <router-view></router-view>
      <BottomTabBar :class="{'bottom-tab': !mobileFlag}" :path="$route.path" v-if="$route.meta.daoBottomTabBar"/>
    </div>
</template>

<script>
import BottomTabBar from '../../components/m-dao/BottomTabBar.vue'
export default {
  name: '',
  components: { BottomTabBar },
  data () {
    return {
      mobileFlag: false
    }
  },
  methods: {
    // 判断当前终端
    isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      this.mobileFlag = flag
    }
  },
  mounted () {
    this.isMobile()
  }
}
</script>

<style scoped lang="scss">
.dap-wrapper {
  height: 100%;
  .bottom-tab {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>
